var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c("router-view", {
        attrs: {
          name: "sidebar",
          clients: _vm.clients,
          "login-client": _vm.distClient,
        },
      }),
      _c(
        "v-slide-x-transition",
        { attrs: { mode: "out-in" } },
        [_c("router-view", { key: "" + _vm.$route.params.piecePath })],
        1
      ),
      _c("client-storage", {
        attrs: { client: _vm.distClient, "storage-key": "dezrann-user" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }