/* eslint-disable max-classes-per-file */
// Helper for generating Opaque types.
export type UnitValue<T, K> = T & { __opaque__: K };

// The base Unit used in dezrann
// Can be cast to and from number using 'as'
// This type system completely disappears on compilation
// https://stackoverflow.com/questions/26810574

/**
 * A numerical value in musical time
 */
export type Onset = UnitValue<number, 'Onset'>;

/**
* The difference between two musical time values
*/
export type Duration = UnitValue<number, 'Duration'>;

/**
 * A numerical value in pixels
 */
export type Pixel = UnitValue<number, 'Pixel'>;
/**
 * A numerical value in seconds
 */
export type Second = UnitValue<number, 'Second'>;

export interface Range<T> {
  start: T,
  end: T,
  width: T,
}

export interface Interval {
  onset: Onset;
  offset: Onset;
  duration: Duration;
}

/**
 * An internal type to index the UnitPair tuple
 */
enum Dir {
  ToOnset = 0,
  ToUnit = 1,
}

/**
 * Reexport Dir with a more readable name for use outside
 */
export type BinSearchDirection = Dir;
export const BinSearchDirection = Dir;

/**
 * A pair of equivalent values in two different numerical types
 *
 * Has an untyped default value
 *
 * Internally just a typescript tuple, or fixe-sized array
 */
export type UnitPair<
  From extends number = number,
  To extends number = number,
  Repeat extends number = number
> =
  Readonly<[From, To, Repeat?]>;

/**
 * A sorted table of equivalent pair, that can be binary searched
 *
 * Has an untyped default value
 */
export type UnitTable<
  From extends number = number,
  To extends number = number,
  Repeat extends number = number
> =
  UnitPair<From, To, Repeat>[];

export default class Unit<Target extends number> {
  // Protected constructor, used by public functions
  protected constructor(tables: UnitTable[], scale = 1) {
    this.tables = tables;

    this.filteredTables = {
      null: [],
    };
    for (let i = 0; i < this.tables.length; i += 1) {
      for (let j = 0; j < this.tables[i].length; j += 1) {
        if (this.tables[i][j][2] === undefined) {
          this.filteredTables.null
            .push(this.tables[i][j] as unknown as UnitTable<number, number, number>);
        } else {
          this.repeatTableIndex = i;
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if (!this.filteredTables[this.tables[i][j][2] as any]) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            this.filteredTables[this.tables[i][j][2] as any] = [];
          }
          this.filteredTables[this.tables[i][j][2] as unknown as string]
            .push(this.tables[i][j] as unknown as UnitTable<number, number, number>);
        }
      }
    }

    this.scale = scale;
  }

  readonly repeatTableIndex: number = 0;

  readonly scale: number;

  readonly tables: UnitTable[];

  filteredTables!: Record<string, UnitTable[]>;

  hasRepeat(repeat: number): boolean {
    return this.filteredTables[repeat].length > 0;
  }

  addRepeat(): void {
    // Get all keys in filteredTables
    const keys = Object.keys(this.filteredTables);
    // Remove 'null' table
    keys.splice(keys.findIndex((k) => k === 'null'), 1).sort();
    // Find last letter
    let last;
    if (keys.length) {
      last = keys[keys.length - 1].charCodeAt(0);
    } else {
      last = 96;
    }
    // Create next letter with last point from last array
    if (last < 122) {
      const next = String.fromCharCode(last + 1);

      let firstPoint;
      let lastPoint;
      if (keys.length) {
        // eslint-disable-next-line prefer-destructuring
        firstPoint = this.filteredTables[keys[keys.length - 1]][0];
        lastPoint = this.filteredTables
          // eslint-disable-next-line no-unexpected-multiline
          [keys[keys.length - 1]][this.filteredTables[keys[keys.length - 1]].length - 1];
      } else {
        // eslint-disable-next-line prefer-destructuring
        firstPoint = this.filteredTables.null[0];
        lastPoint = this.filteredTables.null[this.filteredTables.null.length - 1];
      }
      const points = [
        [
          firstPoint[0],
          lastPoint[1] as unknown as number + 0.1,
          next as unknown as number,
        ] as UnitTable, [
          lastPoint[0],
          lastPoint[1] as unknown as number + 0.5,
          next as unknown as number,
        ] as UnitTable,
      ];
      this.filteredTables[next] = points;
      this.tables[this.repeatTableIndex] = [
        ...this.tables[this.repeatTableIndex],
        ...points as unknown as UnitTable,
      ];
    }
  }

  /**
   * Convert from musical time to this unit
   * @param onset a numerical value in musical time
   * @returns a numerical value of in unit Target
   */
  fromOnset(
    onset: Onset,
    repeat: number | undefined = undefined,
    tableIndex: number | undefined = undefined,
  ): { value: Target, repeat?: number } {
    let tables;
    if (tableIndex) {
      tables = [this.tables[tableIndex]];
    } else if (repeat) {
      tables = [this.filteredTables[repeat]] as unknown as UnitTable<number, number, number>[];
    } else {
      tables = this.tables;
    }
    const bin = tables.reduce(
      Unit.binSearchToTarget,
      { value: onset, repeat },
    );
    return {
      value: bin.value * this.scale as Target,
      repeat: bin.repeat,
    };
  }

  /**
   * @returns the onsets coverage rate
   */
  onsetsCoverage(): number {
    const lastOnset = this.tables[0].slice(-1)[0][0];
    return this.tables[0].length / lastOnset;
  }

  /**
   * Convert from a musical time interval to an interval in this unit
   * @param onset the start of the interval
   * @param duration the end of the interval
   * @returns an interval in this unit, with a start, end and width
   */
  fromInterval(
    onset: Onset,
    duration: Duration,
    repeat: string | undefined = undefined,
  ): Range<Target> {
    const offset = (onset + duration) as Onset;
    const start = this.fromOnset(onset, repeat as unknown as number).value;
    const end = this.fromOnset(offset, repeat as unknown as number).value;
    return { start, end, width: (end - start) as Target };
  }

  /**
    * Convert from musical time to this unit
    * @param unit a numerical value in unit Target
    * @returns a numerical value in musical type
   */
  toOnset(
    unit: Target,
    snap = false,
    repeat: number | undefined = undefined,
  ): {value: Onset, repeat?: number} {
    const scaled = unit / this.scale;
    const tables = repeat
      ? [this.filteredTables[repeat]] as unknown as UnitTable<number, number, number>[]
      : this.tables;
    const onset = tables.reduceRight(
      Unit.binSearchToOnset,
      { value: scaled, repeat },
    );

    if (snap) return { value: this.snapOnset(onset.value as Onset), repeat: onset.repeat };
    return { value: onset.value as Onset, repeat: onset.repeat };
  }

  // Partial conversion functions
  // Used to apply only some of the steps of the conversion table

  // distanceFromOnset correspond to the number of tables from onset
  // For example:
  // converting onset to seconds on a spectrogram table, distanceFromOnset will be 1
  // Because onset is considered index 0, so seconds have a distanceFromOnset of 1
  // If it were 0, fromOnsetToPartial would return the unmodified onset

  protected fromOnsetToPartial(
    onset: Onset,
    distanceFromOnset: number,
    repeat: number | undefined = undefined,
  ): number {
    const tables = (repeat && repeat !== 'null' as unknown as number)
      ? [this.filteredTables[repeat]] as unknown as UnitTable<number, number, number>[]
      : this.tables;
    let val: number = onset;
    for (let i = 0; i < distanceFromOnset; i += 1) {
      val = Unit.binSearchToTarget({ value: val }, tables[i]).value;
    }
    return val;
  }

  protected fromPartialToTarget(source: number, distanceFromOnset: number): Target {
    let val: number = source;
    for (let i = distanceFromOnset; i < this.tables.length; i += 1) {
      val = Unit.binSearchToTarget({ value: val }, this.tables[i]).value;
    }
    return (val * this.scale) as Target;
  }

  protected fromTargetToPartial(
    target: Target,
    distanceFromOnset: number,
  ): number {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let val: any = { value: target / this.scale };
    for (let i = this.tables.length - 1; i >= distanceFromOnset; i -= 1) {
      val = Unit.binSearchToOnset({ value: val.value }, this.tables[i]);
    }
    return val.value;
  }

  protected fromPartialToOnset(source: number, distanceFromOnset: number): {
    value: number,
    repeat?: number | undefined,
  } {
    let val: {
      value: number,
      repeat?: number | undefined,
    } = {
      value: source,
      repeat: undefined,
    };
    for (let i = distanceFromOnset - 1; i >= 0; i -= 1) {
      val = Unit.binSearchToOnset({ value: val.value, repeat: val.repeat }, this.tables[i]);
    }
    return val;
  }

  /**
   * Convert from an interval in this unity to a musical time interval
   * @param start the start of the interval
   * @param duration the end of the interval
   * @param snap if the onset interval should be aligned to notes
   * @returns an interval in musical time, with a onset, offset and duration
   */
  toInterval(start: Target, width: Target, snap = false): Interval {
    const end = (start + width) as Target;
    const onset = this.toOnset(start, snap).value;
    const offset = this.toOnset(end, snap).value;
    return { onset, offset, duration: (offset - onset) as Duration };
  }

  /**
   * Snaps an onset value to the closest node in the onset unit table
   */
  snapOnset(onset: Onset): Onset {
    return this.boundOnset(onset, true);
  }

  /**
   * Snaps an onset value to the bounds if out of bounds
   * Can also snap to nodes like `snapOnset`
   */
  boundOnset(onset: Onset, snap = false): Onset {
    const table = this.tables[0];
    return Unit.binSearch(onset, table, Dir.ToOnset, Dir.ToOnset, snap).value as Onset;
  }

  /**
   * Checks if a unit number will be clamped on conversion to onset
   * @param unit the number to check
   * @returns -1 if before start of score, 0 if inside score, 1 if after score
   */
  isOutOfBounds(unit: Target): number {
    const scaled = unit / this.scale;

    const lastTable = this.tables[this.tables.length - 1];
    const [prevIndex, nextIndex] = Unit.getBounds(scaled, lastTable, Dir.ToUnit);

    if (!Number.isFinite(prevIndex)) return -1;

    if (!Number.isFinite(nextIndex)) return 1;

    return 0;
  }

  /**
   * Snaps an onset value to the closest node in the onset unit table
   */
  snapInterval(onset: Onset, duration: Duration): Interval {
    return this.boundInterval(onset, duration, true);
  }

  /**
   * Snaps an onset value to the bounds of the unit table,
   */
  boundInterval(onset: Onset, duration: Duration, snap = false): Interval {
    const offset = (onset + duration) as Onset;
    const snappedOnset = this.boundOnset(onset, snap);
    const snappedOffset = this.boundOnset(offset, snap);
    return {
      onset: snappedOnset,
      offset: snappedOffset,
      duration: (snappedOffset - snappedOnset) as Duration,
    };
  }

  /**
   * A bin search in a table, in the direction of the local unit
   */
  protected static binSearchToTarget(
    element: { value: number, repeat?: number },
    table: UnitTable,
  ): { value: number, repeat?: number } {
    const bin = Unit.binSearch(element.value, table, Dir.ToOnset, Dir.ToUnit);
    return bin;
  }

  /**
   * A bin search in a table, in the direction of musical time
   */
  protected static binSearchToOnset(
    element: { value: number, repeat?: number },
    table: UnitTable,
  ): { value: number, repeat?: number } {
    const bin = Unit.binSearch(element.value, table, Dir.ToUnit, Dir.ToOnset);
    return bin;
  }

  /**
   * A binary search, returning the interpolated value
   * If a search value is out of the range, it will be clamped to the range
   * @param element the search value
   * @param table the table to search
   * @param from the index of the unit pair to use for the search
   * @param to the index of the unit pair to use for the return value
   * @param snap if the result should be interpolated or simply be the closest node
   * @returns A converted numerical value according to the table and direction
   */
  protected static binSearch(
    element: number,
    table: UnitTable,
    from: Dir,
    to: Dir,
    snap = false,
  ): { value: number, repeat?: number } {
    const [prevIndex, nextIndex] = Unit.getBounds(
      element,
      table,
      from,
    );

    if (!Number.isFinite(prevIndex)) {
      return { value: table[0][to], repeat: table[0][2] };
    }

    if (!Number.isFinite(nextIndex)) {
      return {
        value: table[table.length - 1][to],
        repeat: table[table.length - 1][2],
      };
    }

    const prev = table[prevIndex];
    const next = table[nextIndex];

    const alpha = (element - prev[from]) / (next[from] - prev[from]);

    const snapped = alpha <= 0.5
      ? { value: prev[to], repeat: prev[2] } : { value: next[to], repeat: next[2] };
    if (snap) {
      return snapped;
    }

    return { value: prev[to] + (next[to] - prev[to]) * alpha, repeat: snapped.repeat };
  }

  /**
   * The binary search algorithm, returns an index range
   * @param element the search value
   * @param table the table to search
   * @param from the index of the unit pair to use for the search
   * @returns An ordered range, where one of the bounds may be infinite
   */
  protected static getBounds(
    element: number,
    table: UnitTable<number>,
    from: Dir,
  ): [number, number] {
    let first = 0;
    let last = table.length - 1;

    if (typeof element !== 'number') throw new Error('Searching with undefined value');

    if (element <= table[first][from]) {
      // eslint-disable-next-line dot-notation
      return [-Infinity, first];
    }
    if (element >= table[last][from]) {
      return [last, Infinity];
    }
    while (first < last - 1) {
      // Ratio optimization
      // Instead of looking for the middle like a binary search,
      // use the ratios of the nodes as a hint

      // Calculate where in the range we're looking
      const ratio = (table[first][from] - element) / (table[first][from] - table[last][from]);
      // Make sure that the ratio is between 0 and 1, and not NaN
      const clampedRatio = Math.min(1, Math.max(0, ratio || 0));
      // Calculate the index
      const index = Math.round(first + (last - first) * clampedRatio);
      // Clamp the index so that it cannot equal either end of the range
      const clampedIndex = Math.min(Math.max(index, first + 1), last - 1);
      // Get the row using the index
      const middle = table[clampedIndex];
      // Move the search range
      if (element > middle[from]) {
        first = clampedIndex;
      } else last = clampedIndex;
    }
    return [first, last];
  }

  getScaled(scale: number): Unit<Target> {
    return new Unit(this.tables, this.scale * scale);
  }

  /**
   * Create a new unit object that is faster and smaller
   * It however cannot be used for "snapping", as many nodes are removed
   * @returns a faster Unit<Target>
   */
  getOptimized(): Unit<Target> {
    return new Unit(this.tables.map(Unit.optimizeTable), this.scale);
  }

  /**
   * Check that a unit is an extension of a another unit (for waveforms)
   * @param track the possible parent unit
   * @returns true if this is a child of track
   */
  isChildOfUnit(parent: Unit<number>): boolean {
    return parent.tables.every((trackTable, index) => trackTable === this.tables[index]);
  }

  /**
   * Return a table with some rows removed
   * Rows are removed if they are very close to the interpolation of their neighbors
   * @param table
   * @returns
   */
  protected static optimizeTable(table: UnitTable): UnitTable {
    return table.filter((row, index, target) => {
      if (index === 0 || index === target.length - 1) return true;

      const prevRow = target[index - 1];
      const nextRow = target[index + 1];

      const interpolatedFrom = (prevRow[0] - row[0]) / (prevRow[0] - nextRow[0]);
      const interpolatedTo = prevRow[1] + (nextRow[1] - prevRow[1]) * interpolatedFrom;

      return Math.abs(interpolatedTo - row[1]) > 0.000001;
    });
  }
}
